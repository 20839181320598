import React from "react";
import Home from "./views/Home";
import { Route, BrowserRouter } from "react-router-dom";
import { theme } from "./assets/theme";
import { ThemeProvider } from "@material-ui/styles";
import ReactGA from "react-ga";

ReactGA.initialize("UA-135134927-7");

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Route exact path="/" component={Home} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
