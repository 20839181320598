import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#181818"
    },
    secondary: {
      main: "#4A4A4A",
      lightGrey: "#D0D0D0",
      offWhite: "#F7F7F7",
      teal: "#00BFA5",
      blue: "#4A90E2"
    },
    status: {
      purple: "#AD8AE8",
      blue: "#4285F4",
      yellow: "#FFC400"
    }
  }
});
