import React, { useState } from "react";
import styled from "styled-components";
import BackgroundMD from "../assets/imgs/Background-medium.png";
import Background from "../assets/imgs/Background.png";
import BackgroundSM from "../assets/imgs/background-small.jpg";
import LogoImg from "../assets/imgs/VIVOpago-logo-white text.svg";
import contactImg from "../assets/imgs/contact.png";
import ReactGA from "react-ga"
import { withStyles, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";

import './Home.css';

const Main = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
`;

const Cover = styled.div`
  width: 100%;
  min-height: 600px;
  /* background-size: "cover"; */
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: url(${Background});
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1440px) {
    background: url(${BackgroundMD});
    height: 70vh;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 900px) {
    width: 100vw;


    background: url(${BackgroundSM});
    background-repeat: no-repeat;
    background-position: top;
  }
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  height: 4rem;
  width: 80vw;

  /* max-width: 1200px; */
`;

const CoverCenter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FooterTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Social = styled.div`
  display: flex;
  flex-flow row nowrap;
  justify-content: space-between;
  width: 156px;
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

const SocialLink = styled.a`
  color: inherit;
`;

const Logo = styled.img``;
const Right = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
`;
const Middle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;
  margin-top: 4rem;
  max-width: 1200px;
  width: 100vw;
  align-self: center;
  @media (max-width: 600px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  /* margin: auto; */
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Footer = styled.div`
  /* height: 228px; */
  width: 100%;
  /* max-width: 1200px; */
  background-color: #6a1b9a;
  align-self: center;
  justify-content: space-between;

  display: flex;
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.span`
  height: 70px;
  color: #ffffff;
  font-family: Helvetica;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -0.19px;
  line-height: 71px;
  text-align: center;
  @media (max-width: 600px) {
    height: 100%;
    text-align: center;
    font-size: 36px;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  align-self: center;
  margin: auto;
  margin-top: 3rem;
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;


const EmailBlurb = styled.span`
  color: #ffffff;
  font-family: Helvetica;
  font-size: 16px;
  letter-spacing: -0.28px;
  line-height: 19px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-width: 556px;
  text-align: left;
`;


const MiddleHeading = styled.span`
  height: 120px;
  width: 451px;
  color: #000000;
  font-family: Helvetica;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 57px;
  margin-top: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 36px;
  }
`;
const ContactUsText = styled.span`
  height: 19px;
  width: 439px;
  color: #4a4a4a;
  font-family: Helvetica;
  font-size: 16px;
  letter-spacing: -0.28px;
  line-height: 19px;

  margin-top: 2rem;
`;
const ContactImage = styled.img`
  width: 100%;
`;

const ContactUsButton = withStyles({
  root: {
    backgroundColor: "#9C4DCC",
    height: "42px",
    width: "175px",
    color: "#FFFFFF",
    marginTop: "2rem",
    "&:hover": {
      backgroundColor: '#723995'
    }
  },
})(Button);

const FooterItem = styled.span`
  /* height: 16px; */
  /* width: 87px; */

  margin-bottom: 1rem;
  color: #ffffff;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 16px;
`;

const SignUpForm = styled.form`
  padding: 1rem;
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SignUpFieldContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 556px;

  @media (max-width: 600px) {
    flex-flow: column nowrap;
  }
`;

const SignUpButtonContainer = styled.div``;

const SignUpButton = styled.input``;

const useStyles = makeStyles(()=> ({
  inputClass: {
    '& .MuiFilledInput-input': {
      padding:"27px 12px 2px",
    },
  },
  labelClass: {
    lineHeight:"0.5"
  }
}))

const Home = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [email, setEmail] = useState("");
  const classes = useStyles()
  const changeEmail = event => {
    setEmail(event.target.value);
  };

  return (
    <Main>
      <Cover>
        <Nav>
          <Logo src={LogoImg} alt="" />
          <Right />
        </Nav>
        <CoverCenter>
          <div id="mc_embed_signup">
            <SignUpForm action="https://mavennet.us4.list-manage.com/subscribe/post?u=4aec2ec3f3f65f732cdb4e593&amp;id=902d82b993"
              method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate
            >
              <SignUpContainer id="mc_embed_signup_scroll">
              	<Heading>We're coming soon!</Heading>
                <EmailBlurb>
                  Enter your email below to get updated information on our launch.
                </EmailBlurb>
                <SignUpFieldContainer>
                  <input type="email" value={email} onChange={changeEmail} name="EMAIL" className="email" id="mce-EMAIL" placeholder="Your Email Address" required />
                  {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->*/}
                  <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_4aec2ec3f3f65f732cdb4e593_902d82b993" tabindex="-1" value="" /></div>
                  <SignUpButtonContainer className="clear">
                    <SignUpButton type="submit" value="KEEP ME UPDATED" name="subscribe" id="mc-embedded-subscribe" className="button" />
                  </SignUpButtonContainer>
                </SignUpFieldContainer>
              </SignUpContainer>
            </SignUpForm>
          </div>
        </CoverCenter>
      </Cover>
      <Middle>
        <Left>
          <MiddleHeading>Innovate the future of advertising.</MiddleHeading>
          <ContactUsText>
            Have interest in VIVOpago? Contact us to learn more!
          </ContactUsText>
          <ContactUsButton onClick="">Contact Us</ContactUsButton>
        </Left>
        <Right>
          <ContactImage src={contactImg} />
        </Right>
      </Middle>
      <Footer>
        <FooterContent>
          <FooterTop>
            <Left>
              <Logo src={LogoImg} style={{ marginBottom: "2rem" }}></Logo>
              <FooterItem>Terms and Conditions</FooterItem>
              <FooterItem>Privacy Policy</FooterItem>
              <FooterItem>Help and FAQs</FooterItem>
            </Left>
            <Right>
              <FooterItem>Contact Us</FooterItem>
              <FooterItem>info@vivopago.io</FooterItem>
            </Right>
          </FooterTop>
          <Center>
            <Social>
              <SocialLink href="https://www.facebook.com/VIVOpago-706275083081499/"><FontAwesomeIcon icon={faFacebook} /></SocialLink>
              <SocialLink href="https://twitter.com/vivopago"><FontAwesomeIcon icon={faTwitter} /></SocialLink>
              <SocialLink href="https://www.linkedin.com/company/vivo-play/"><FontAwesomeIcon icon={faLinkedin} /></SocialLink>
              <SocialLink href="https://www.instagram.com/vivopago/"><FontAwesomeIcon icon={faInstagram} /></SocialLink>
            </Social>
            <FooterItem>VivoPago © 2019. All rights reserved.</FooterItem>
          </Center>
        </FooterContent>
      </Footer>
    </Main>
  );
};

export default Home;
